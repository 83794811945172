// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-th-js": () => import("./../../../src/pages/index.th.js" /* webpackChunkName: "component---src-pages-index-th-js" */),
  "component---src-pages-index-tw-js": () => import("./../../../src/pages/index.tw.js" /* webpackChunkName: "component---src-pages-index-tw-js" */)
}

